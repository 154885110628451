/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Page two" />

    <div
      sx={{
        m: `1.45rem auto`,
        maxWidth: 900,
      }}
    >
      <h1>Hi from the second page</h1>
      <p>Welcome to page 2</p>      
    </div>


    <center>
      <Link to="/">Home</Link>
      {" "}
      <Link to="/index-blog/">Blog</Link>
      {" "}
      <Link to="/index-project/">Projects</Link>
    </center>
  </Layout>
)

export default SecondPage
